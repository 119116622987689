$bg: #0B122C;

body {
  margin: 0;
  padding: 0;
  background: $bg;
  overflow: hidden;
  user-select: none;
}

.container {
  height: 100vh;
  overscroll-behavior-y: contain;
}

.cols {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.col {
  flex: 1;
  text-align: center;
  padding-left: 20px;
  overflow: hidden;

  display: flex;
  align-items: center;
}

.col .name-tag {
  color: white;
  width: 30px;
  height: 140px;
  border-radius: 15px;
  display: block;
  box-sizing: border-box;
  word-break: break-all;

  display: flex;
  align-items: center;
  justify-content: center;
  writing-mode: vertical-lr;

  line-height: 30px;
  font-size: 18px;
  padding: 35px 0;
  background: RGB(var(--primary));
  margin-right: 10px;
  
  position: relative;
}

.col .name-tag .material-icons {
  position: absolute;
  font-size: 12px;
  height: 100%;
  right: 15px;
  top: 0;
  line-height: 30px;
}

.col-inner {
  position: relative;
  flex: 1;
}

.hint {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  writing-mode: vertical-lr;

  color: rgba(255,255,255,.54);
  font-size: 12px;
  background: $bg;
}

.tag {
  color: rgba(255,255,255,.4);
  line-height: 24px;
  font-size: 11px;
  position: absolute;
  left: 0;
  top: 0;
  width: 52px;
  text-align: center;
}

.segment {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  transform-origin: top left;

  opacity: .5;
}

.segment-increase {
  background: linear-gradient(180deg, RGBA(var(--primary), 0), RGBA(var(--primary), 0.1));
}

.segment-worsen {
  background: linear-gradient(180deg, RGBA(var(--primary), 0.1), RGBA(var(--primary), 0.3));
}

.segment-max {
  background: linear-gradient(180deg, RGBA(var(--primary), 0.3), RGBA(var(--primary), 0.1));
}

.canvas {
  position: relative;
  opacity: .7;
}

.backdrop {
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  background-color: rgba(0,0,0,.7);
  backdrop-filter: blur(2px);
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity .2s ease;
  opacity: 0;
  pointer-events: none;
}

.backdrop-active {
  opacity: 1;
  pointer-events: all;
}

.select-dialog {
  width: 140px;
  background: $bg;
  box-shadow: rgba(0,0,0,.3) 0 2px 6px;
}

.select-entry {
  color: rgba(255,255,255,.87);
  font-size: 16px;
  line-height: 40px;
  text-align: center;
}

.breakpoint {
  transform-origin: top;
  height: 120px;
  background: #121212;
  position: absolute;
  top: 0;
  left: -10px;
  right: -10px;
  padding: 0 10px;
  transition: transform .1s ease;
  box-shadow: rgba(0,0,0,.3) 0 2px 6px inset;
}

.detail {
  position: absolute;
  left: 0;
  top: 0;
  height: 120px;
  text-align: left;
  transform-origin: 7px 10px;
  width: 120px;
}

.col-left .detail {
  top: unset;
  bottom: -80px;
}

.detail-icon {
  position: relative;
  width: 14px;
  height: 14px;
  margin-right: 5px;
  line-height: 20px;
  height: 20px;
}

.detail-info {
  display: flex;
  align-items: center;
  height: 24px;
}

.detail-cate {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}

.detail-text {
  color: rgba(255,255,255,.87);
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 21px;
}

.detail-date {
  color: rgba(255,255,255,.87);
  font-size: 14px;
  font-weight: 700;
  padding-left: 21px;
}

.graph-hint {
  position: fixed;
  top: 0;
  bottom: 0;
  line-height: 20px;
  left: 35px;
  width: 20px;
  text-align: center;
  writing-mode: vertical-lr;
  font-size: 10px;
  color: rgba(255,255,255,.54);
}

.tag-highlight {
  color: white;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  height: 24px;
}

.scroll-ind {
  position: fixed;
  top: 60px;
  bottom: 0;
  left: calc(50vw - 2px);
  width: 4px;
  background: #26B3CF;
}

.scroll-ind-top {
  position: absolute;
  top: 0;
  left: 50%;
  white-space: nowrap;
  display: inline-block;
  color: white;

  transform: translate(-50%, -50%);
}

.col-expanded .segment {
  opacity: 0;
}

.col-expanded .hint {
  opacity: 0;
}

.held .detail-expanded .detail-icon {
  opacity: 0;
}

.held .detail-expanded .detail-text {
  opacity: 0;
}

.held .detail-expanded .detail-cate {
  opacity: 0;
}

.held .detail-expanded .detail-date {
  opacity: 0;
}

.detail-text {
}

.detail-cate {
}

.detail-date {
}

.arrow-down {
  border-top: white 4px solid;
  border-left: white 4px solid;
  border-right: transparent 4px solid;
  border-bottom: transparent 4px solid;
  transform: rotate(45deg+90deg);
  display: inline-block;
  box-sizing: border-box;
  width: 4px;
  height: 4px;
  margin-top: 10px;
}

.back {
  position: fixed;
  right: 18px;
  color: rgba(255,255,255,.54);
  font-size: 12px;
  top: 60px;

  pointer-events: none;
  opacity: 0;
  transition: opacity .2s ease;

  &-shown {
    opacity: 1;
    pointer-events: all;
  }
}

.extra-tag {
  transition: opacity .2s ease;
  opacity: 0;

  &-shown {
    opacity: 1;
  }
}

.last-tag {
  position: fixed;
  top: unset;
  bottom: 0;
}

.col-left canvas {
  transform: scaleY(-1);
}

.terminal {
  position: fixed;
  transition: opacity .2s ease;
  pointer-events: none;

  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;

  font-size: 12px;
  color: rgba(255,255,255,.54);

  display: flex;
  align-items: center;
  justify-content: center;
}

.cnt {
  color: rgba(255,255,255,.3);
  line-height: 24px;
  font-size: 10px;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 6px;
}

.col-left .cnt {
  top: auto;
  bottom: 0;
}

.intro-1 {
  transform: translateX(60px);
}

.intro-2 {
}

.intro-3 {
  position: absolute;
  top: 60px;
  right: 18px;
}

.intro-4 {
  position: absolute;
  top: 30px;
}

.intro-confirm {
  line-height: 26px;
  width: 80px;
  text-align: center;
  border-radius: 13px;
  background: white;
  color: black;
  font-size: 14px;
  position: absolute;
  top: 80vh;
}

.intro-title {
  font-size: 17px;
  color: white;
}

.intro-hint {
  font-size: 14px;
  color: white;
  max-width: 255px;
  margin-top: 33px;
}

.intro-home {
  display: flex;
  flex-direction: column;
  align-items: center;
}
